import { formatISODateTime, formatISODateTimeToTimeStamp } from "../formatters";
import { arrayFrom, stringFrom } from "../parsing";
export function formatBrandData(brand) {
  return {
    id: stringFrom(brand.id),
    createdTimestamp: formatISODateTimeToTimeStamp(
      stringFrom(brand.created_on)
    ),
    name: stringFrom(brand.name),
    logo: stringFrom(brand.asset_details.url),
    addressLine1: stringFrom(brand.address_details.address_line_1),
    addressLine2: stringFrom(brand.address_details.address_line_2),
    pincode: stringFrom(brand.address_details.pincode),
    location: stringFrom(brand.address_details.location),
    city: stringFrom(brand.address_details.city),
    state: stringFrom(brand.address_details.state),
  };
}
export function formatAdditionalCharges(additionalCharges) {
  return {
    orders: additionalCharges
      .filter((item) => item.context_type === "orders")
      .map((item) => {
        return {
          id: stringFrom(item.id),
          name: stringFrom(item.header_name),
        };
      }),
    quotes: additionalCharges
      .filter((item) => item.context_type === "quotes")
      .map((item) => {
        return {
          id: stringFrom(item.id),
          name: stringFrom(item.header_name),
        };
      }),
  };
}

export function formatComments(comment) {
  return {
    id: stringFrom(comment.id),
    text: stringFrom(comment.text),
    createdBy: comment.contact_details?.name
      ? stringFrom(comment.contact_details.name)
      : "",
    createdOn: formatISODateTime(stringFrom(comment.created_at), "dateTime"),
    assets: arrayFrom(comment.asset_details).map((item) => ({
      id: comment.fk_asset,
      assetData: {
        id: item.id,
        src: item.url,
        name: item.name,
        type: item.type,
        description: item.description,
      },
    })),
  };
}

export function formatLogsData(logs) {
  const projects = {};
  const contexts = {};
  const modifiers = {};
  const logList = logs
    .map((log) => {
      const context = stringFrom(log.context_type);
      const formattedContext =
        context == "files"
          ? "Files"
          : context == "client_progress_status"
          ? "Client Progress Status"
          : context === "tasks"
          ? "Task"
          : context === "project_manpower"
          ? "Project Manpower"
          : context === "activity_status"
          ? "Activity Status"
          : context === "notes"
          ? "Notes"
          : context;
      const commentContext =
        context == "files"
          ? "file"
          : context == "client_progress_status" || context === "activity_status"
          ? "activity"
          : context === "tasks"
          ? "task"
          : context === "project_manpower"
          ? "manpower"
          : context === "notes"
          ? "note"
          : context;
      const projectId = stringFrom(log.project_details.id);
      const projectName = stringFrom(log.project_details.name);
      const modifierId = stringFrom(log.contact_details.id);
      const modifierName = stringFrom(log.contact_details.name);
      if (!projects[projectId]) {
        projects[projectId] = projectName;
      }
      if (!contexts[context]) {
        contexts[context] = formattedContext;
      }
      if (!modifiers[modifierId]) {
        modifiers[modifierId] = modifierName;
      }
      log.context_details["commentContext"] = commentContext;

      if (context === "client_progress_status") {
        log.context_details.id =
          log.context_details.fk_project_client_progress.id;
      }
      if (context === "activity_status") {
        log.context_details.id = log.context_details.fk_project_activity.id;
      }
      return {
        id: stringFrom(log.id),
        logDateTime: formatISODateTime(stringFrom(log.updated_at), "dateTime"),
        logTimestamp: formatISODateTimeToTimeStamp(stringFrom(log.updated_at)),
        contextDetails: log.context_details,
        projectId,
        projectName,
        modifierId,
        modifierName,
        context,
        formattedContext,
        assets: arrayFrom(log.asset_details || []).map((asset) => ({
          id: asset?.id,
          src: asset?.url,
          name: asset?.name,
          type: asset?.type,
          description: asset?.description,
        })),
        commentContext: commentContext,
        tab:
          context == "client_progress_status"
            ? "clientProgress"
            : context === "project_manpower"
            ? "manpower"
            : context === "activity_status"
            ? "activities"
            : context,
      };
    })
    .sort((a, b) => b.logTimestamp - a.logTimestamp);

  return {
    logs: logList,
    projects: Object.entries(projects).map(([id, name]) => ({ id, name })),
    contexts: Object.entries(contexts).map(([id, name]) => ({ id, name })),
    users: Object.entries(modifiers).map(([id, name]) => ({ id, name })),
  };
}

export function formatPrimaryProjectStageData(stage) {
  return {
    id: stringFrom(stage.id),
    name: stringFrom(stage.name),
    type: stringFrom(stage.type),
    sortIndex: stage.sort_index,
    project_count: stage.project_count,
  };
}
export function formatItemTags(tag) {
  return {
    id: stringFrom(tag.id),
    name: stringFrom(tag.name),
  };
}
