import { apiRequestHandler } from "@/services/rest-api-manager";
export function getIndianStates() {
  return apiRequestHandler("fetchStates")
    .then((response) => {
      if (response.success) {
        return response.data;
      }
    })
    .catch((error) => {
      this.$store.dispatch("showSnackbar", {
        show: true,
        text: error.message || error,
        color: "error",
      });
    });
}

export function getCitiesData(id) {
  return apiRequestHandler("fetchCitiesByState", id)
    .then((response) => {
      if (response.success) {
        return response.data;
      }
    })
    .catch((error) => {
      this.$store.dispatch("showSnackbar", {
        show: true,
        text: error.message || error,
        color: "error",
      });
    });
}
